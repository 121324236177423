<template>
    <dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        <h3>
                            Choose types of food served at your venue
                        </h3>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col v-for="ft in foodTypes" :key="ft.id" cols="12" md="4" lg="3">
                            <v-checkbox
                                v-model="foodTypesSelected"
                                :label="ft.name"
                                :value="ft"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn @click="save">Save</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </dashboard-layout>
</template>

<script>
import FoodType from "@/models/FoodType";
import Venue from "@/models/Venue";
import DashboardLayout from "@/layouts/DashboardLayout";

export default {
    name: "food-type-sync",
    components: {DashboardLayout},
    data: function () {
        return {
            foodTypes: [],
            foodTypesSelected: [],
        }
    },
    methods: {
        async save() {
            await window.axios.put('/api/food-types/sync', {
                food_types: this.$_.map(this.foodTypesSelected, eq => eq instanceof FoodType ? eq.id : eq)
            })
        },
        onChangeCombobox(items) {
            this.foodTypesSelected = items.filter((item) => this.foodTypes.includes(item))
        }
    },
    async mounted() {
        this.foodTypes = await FoodType.get();

        const venue = await Venue.where('user_id', this.$auth.user().id).first();

        if (!venue) {
            return
        }

        const venueFoodTypes = await FoodType.where('venue', venue.id).get();

        this.foodTypesSelected = this.foodTypes.filter(ft => {
            for (const vft of venueFoodTypes) {
                if (vft.id === ft.id) {
                    return true
                }
            }

            return false;
        })
    }
}
</script>

<style scoped>

</style>
